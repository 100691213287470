/* Overrides */
body {
	background-image: url('/img/stardust.png');
	color-scheme: light;

	&[theme=dark] {
		color-scheme: dark;
	}
}

.page {
	background-color: $global-background-color;
	padding: 0 1rem;

	body[theme=dark] & {
		background-color: $global-background-color-dark;
	}
}

.home {
	.home-profile {
		text-align: left;
	}

	.home-title {
		font-size: 1.5rem !important;
	}
}

.archive .single-title {
	text-align: left;
}

.home[data-home="posts"] .summary .content {
	display: block;
}

.single .post-footer .post-info .post-info-line .post-info-md {
	width: unset;
}

.single .content {
	figure {
		text-align: left;

		.image-caption:not(:empty) {
			min-width: unset;
			max-width: unset;
		}
	}

	pre {
		overflow-x: auto;
	}

	.highlight > .chroma .code-header {
		[lang=ru] & .code-title::after {
			content: 'Код';
		}

		// Duplicating, since otherwise it will be rewritten
		@each $type, $text in $code-type-map {
			&.#{$type} .code-title::after {
				content: $text;
			}
		}
	}
}

.archive .tag-cloud-tags {
	a:active, a:focus, a:hover {
	  -webkit-transform: none;
	  -moz-transform: none;
	  -ms-transform: none;
	  -o-transform: none;
	  transform: none;
	}
} 

/* Card */
.link-card {
	display: flex;
	margin-top: 15px;
	background: $code-background-color;
	color: $global-font-color;

	[theme=dark] & {
		background: $code-background-color-dark;
		color: $global-font-color-dark;
	}
}

.link-card-image {
	min-width: 15rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media only screen and (max-width: 960px) {
		& {
			min-width: 12rem;
		}
	}

	@media only screen and (max-width: 680px) {
		& {
			min-width: 8rem;
		}
	}
}

.link-card-body {
	width: 100%;
}

.link-card-title {
	font-weight: bold;
	font-size: 1.25rem;
	padding: 10px 15px 0 15px;
	color: $single-link-color;

	[theme=dark] & {
		color: $single-link-color-dark;
	}
}

.link-card-description {
	font-size: 0.9rem;
	line-height: 1.5rem;
	padding: 5px 15px 10px 15px;
}

.link-card-info {
	font-size: 0.8rem;
	position: relative;
	padding: 10px 15px;
	color: $global-font-secondary-color;

	[theme=dark] & {
		color: $global-font-secondary-color-dark;
	}
}

.link-card-footer {
	font-size: 0.85rem;
	line-height: 1.2rem;
	padding: 2px 5px 4px 5px;
	margin-bottom: 15px;
	background: $table-thead-color;
	color: $single-link-color;

	[theme=dark] & {
		background: $table-thead-color-dark;
		color: $single-link-color-dark;
	}
}

@import "_cactus";
