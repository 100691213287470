// ==============================
// Override Variables
// 覆盖变量
// ==============================
// Code type map
$code-type-map: (
  // Custom code type
  language-bash: 'Bash',
  language-batch: 'Windows Batch',
  language-c: 'C',
  language-cs: 'C#',
  language-cpp: 'C++',
  language-clojure: 'Clojure',
  language-coffeescript: 'CoffeeScript',
  language-css: 'CSS',
  language-dart: 'Dart',
  language-diff: 'Diff',
  language-erlang: 'Erlang',
  language-go: 'Go',
  language-go-html-template: 'Go HTML Template',
  language-groovy: 'Groovy',
  language-haskell: 'Haskell',
  language-html: 'HTML',
  language-http: 'HTTP',
  language-xml: 'XML',
  language-java: 'Java',
  language-js: 'JavaScript',
  language-javascript: 'JavaScript',
  language-json: 'JSON',
  language-kotlin: 'Kotlin',
  language-latex: 'LaTeX',
  language-less: 'Less',
  language-lisp: 'Lisp',
  language-lua: 'Lua',
  language-makefile: 'Makefile',
  language-markdown: 'Markdown',
  language-matlab: 'Matlab',
  language-objectivec: 'Objective-C',
  language-php: 'PHP',
  language-perl: 'Perl',
  language-powershell: 'PowerShell',
  language-posh: 'PowerShell',
  language-pwsh: 'PowerShell',
  language-python: 'Python',
  language-r: 'R',
  language-reg: 'Regedit',
  language-ruby: 'Ruby',
  language-rust: 'Rust',
  language-scala: 'Scala',
  language-scss: 'Scss',
  language-shell: 'Shell',
  language-sql: 'SQL',
  language-swift: 'Swift',
  language-tex: 'TeX',
  language-toml: 'TOML',
  language-ts: 'TypeScript',
  language-typescript: 'TypeScript',
  language-vue: 'Vue',
  language-yml: 'YAML',
  language-yaml: 'YAML',
);

// Colors
$global-font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
$global-background-color: #eee;
$global-font-color: #464646;
$global-font-secondary-color: #727279;
$global-font-secondary-color-dark: #87878a;
$global-link-color: #464646;
$global-link-hover-color: #bc792d;
$global-border-color: #dfdfdf;
$header-title-font-family: $global-font-family;
$header-background-color: #f2f2f2;
$header-hover-color: #464646;
$header-hover-color-dark: #ddd;
$single-link-color: #bc792d;
$single-link-color-dark: #e09f55;
$single-link-hover-color: #ed9938;
$single-link-hover-color-dark: #f4d9ba;
$code-font-family: Roboto Mono, Source Code Pro, Menlo, Consolas, Monaco, monospace, $global-font-family;
